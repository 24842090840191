<template>
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <orisis-logo-full class="logo" />
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Accès interdit 🔐 !
        </h2>
        <h4 class="font-weight-bold">
            Cette interface est réservés aux administrateurs.
        </h4>
        <p class="mb-2"><br><br>Vous êtes arrivés ici par erreur ?</br>Merci de vous le signaler par email : <a href="mailto:contact@devinfluence.fr" target="new">contact@devinfluence.fr</a>
        </p>
        <b-button
          variant="primary"
          class="mb-1 btn-sm-block"
          :to="'/login'"
        >← Retour à la page de connexion</b-button>
        <b-img
          fluid
          :src="imgUrl"
          alt="Not authorized page"
        />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { BLink, BImg, BButton } from 'bootstrap-vue'
import store from '@/store/index'
import OrisisLogoFull from "@core/layouts/components/LogoFull.vue";

export default {
  components: {
    BLink, BImg, BButton, OrisisLogoFull,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/not-authorized.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/not-authorized-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';

.logo{
  width: 120px !important;
  margin-left: 25px;
}
</style>
